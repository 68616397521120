import React, { useMemo } from 'react';
import { ColumnDragCard } from './ColumnDragCard';
import { IconButton } from './IconButton';
import './ColumnDragTargetArea.scss';
const TargetBox = ({ field, touched, assignedColumn, dragState, eventBinder, onHover, onAssign, onUnassign }) => {
    const mouseHoverHandlers = dragState && dragState.pointerStartInfo
        ? {
            onMouseEnter: () => onHover(field.name, true),
            onMouseLeave: () => onHover(field.name, false)
        }
        : {};
    const sourceColumn = dragState && dragState.dropFieldName === field.name
        ? dragState.column
        : null;
    // see if currently assigned column is being dragged again
    const isReDragged = dragState ? dragState.column === assignedColumn : false;
    const dragHandlers = useMemo(() => assignedColumn && !isReDragged
        ? eventBinder(assignedColumn, field.name)
        : {}, [eventBinder, assignedColumn, isReDragged, field.name]);
    const valueContents = useMemo(() => {
        if (sourceColumn) {
            return (React.createElement(ColumnDragCard, { rowCount: 3, column: sourceColumn, isDropIndicator: true }));
        }
        if (assignedColumn) {
            return (React.createElement(ColumnDragCard, { rowCount: 3, column: assignedColumn, isShadow: isReDragged, isDraggable: !isReDragged }));
        }
        const hasError = touched && !field.isOptional;
        return React.createElement(ColumnDragCard, { rowCount: 3, hasError: hasError });
    }, [field, touched, assignedColumn, sourceColumn, isReDragged]);
    // @todo mouse cursor changes to reflect draggable state
    return (React.createElement("section", Object.assign({ className: "CSVImporter_ColumnDragTargetArea__box", "aria-label": `${field.label} (${field.isOptional ? 'optional' : 'required'})` }, mouseHoverHandlers),
        React.createElement("div", { className: "CSVImporter_ColumnDragTargetArea__boxLabel", "aria-hidden": true },
            field.label,
            field.isOptional ? null : React.createElement("b", null, "*")),
        React.createElement("div", { className: "CSVImporter_ColumnDragTargetArea__boxValue" },
            !sourceColumn && !assignedColumn && (React.createElement("div", { className: "CSVImporter_ColumnDragTargetArea__boxPlaceholderHelp", "aria-hidden": true }, "Drag column here")),
            React.createElement("div", Object.assign({}, dragHandlers), valueContents),
            dragState && !dragState.pointerStartInfo ? (React.createElement("div", { className: "CSVImporter_ColumnDragTargetArea__boxValueAction" },
                React.createElement(IconButton, { label: `Assign column ${dragState.column.code}`, small: true, type: "forward", onClick: () => onAssign(field.name) }))) : (!sourceColumn &&
                assignedColumn && (React.createElement("div", { className: "CSVImporter_ColumnDragTargetArea__boxValueAction" },
                React.createElement(IconButton, { label: "Clear column assignment", small: true, type: "close", onClick: () => onUnassign(assignedColumn) })))))));
};
export const ColumnDragTargetArea = ({ fields, columns, fieldTouched, fieldAssignments, dragState, eventBinder, onHover, onAssign, onUnassign }) => {
    return (React.createElement("section", { className: "CSVImporter_ColumnDragTargetArea", "aria-label": "Target fields" },
        React.createElement("hr", { className: "CSVImporter_ColumnDragTargetArea__hr" }),
        fields.map((field) => {
            const assignedColumnIndex = fieldAssignments[field.name];
            return (React.createElement(TargetBox, { key: field.name, field: field, touched: fieldTouched[field.name], assignedColumn: assignedColumnIndex !== undefined
                    ? columns[assignedColumnIndex]
                    : null, dragState: dragState, eventBinder: eventBinder, onHover: onHover, onAssign: onAssign, onUnassign: onUnassign }));
        })));
};
